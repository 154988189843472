import { NpsEnum } from "../../enum/nps.enum";

export const props = () => ({
  zendeskId: "df3898b2-73f6-4e0d-b96a-734decbe9d9e",
  apiUrl: "https://api-adm-clientes-dev.graodireto.com.br",
  backofficeUrl: "https://api-adm-backoffice-dev.graodireto.com.br",
  logo: "adm",
  theme: "adm",
  appTitle: "Gestão de Clientes",
  companyName: "ADM",
  companyLanguage: "default",
  pdfUrl: "https://corporate-backoffice-dev.graodireto.com.br/assets",
  keyCloakConfig: {
    url: "https://auth-homolog.graodireto.com.br",
    realm: "adm",
    clientId: "adm-gclients-front",
  },
  production: false,
  firebaseConfig: {
    apiKey: "",
    authDomain: "",
    projectId: "",
    storageBucket: "",
    messagingSenderId: "",
    appId: "",
    measurementId: "",
  },
  hotjarConfig: "",
  nps: {
    api: "https://api-homolog.graodireto.com.br/api",
    url: "https://graodireto-site-homolog.graodireto.com.br/nps",
    key: "4b51b41f-bf44-44db-877f-90f90ed9b7b8",
  },
  npsSourceId: NpsEnum.CLIENT_MANAGEMENT_ADM,
});
